import { useEffect, createRef} from 'react';
import styled from "styled-components";
import Slider from "react-slick";
import Link from 'next/link'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

// const SliderArrow = styled(FontAwesomeIcon)`
//   width: 25px;
//   height: 25px;
//   margin-top: -50px;
//   color: ${(props) => props.theme.main.primary};
//   &:hover {
//     color: ${(props) => props.theme.main.primary};
//   }
// `;

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  // nextArrow: <SliderArrow icon={faChevronRight} />,
  // prevArrow: <SliderArrow icon={faChevronLeft} />,
  responsive: [
    {
      breakpoint: 2560,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
      },
    },
  ],
};

const BigSliderCard = ({ cmsData }: any) => {
  let containerRef: any = createRef();
  let firstClientX: any, clientX;

  const preventTouch = (e: any) => {
    const minValue = 5; // threshold
  
    clientX = e.touches[0].clientX - firstClientX;
  
    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
  
      return false;
    }
  };
  
  const touchStart = (e: any) => {
    firstClientX = e.touches[0].clientX;
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", touchStart, {passive: true});
      containerRef.current.addEventListener("touchmove", preventTouch, {passive: true});
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch);
      }
    };
  });

  return (
    <Container ref={containerRef}>
      <Title>{cmsData.title}</Title>
      <StyledSlider {...settings}>
        {cmsData.camp.map((c: any, index: number) => (
          <Link href={`/kamp-alani/${c.slug}`} passHref key={index}>
            <Card>
              <CardImage loading="lazy" src={c.imagePaths?.[0]} alt={c.title} width={360} height={360} />
              <CardContent>
                <CardTitle>{c.title}</CardTitle>
                <CardDescription>
                  {c.city} / {c.county}
                </CardDescription>
              </CardContent>
            </Card>
          </Link>
        ))}
      </StyledSlider>
    </Container>
  );
};

const Container = styled.div`
  text-align: left;
  margin-bottom: 96px;
`;

const StyledSlider = styled(Slider)`
  margin-top: 24px;
  .slick-dots li button:before {
    color: #868F97 !important;
    font-size: 10px !important;
    opacity: 1 !important;
  }
  
  .slick-dots li.slick-active button:before {
    color: ${(props) => `${props.theme.main.primary} !important`};
  }
`

const Title = styled.span`
  font-size: 32px;
  font-weight: 600;
  font-weight: 600px;
  text-align: left;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justift-content: flex-row;
  align-items: center;
`;

const Card = styled.div`
  padding: 5px 0px;
  position: relative;
  cursor: pointer;
  outline: none;
`;

const CardImage = styled.img`
  width: 100%;
  height: 360px;
  object-fit: cover;
  border-radius: 10px;
  @media (max-width: ${(props) => props.theme.size.laptopL}) {
    width: 100%;
  }
`;

const CardContent = styled.div`
  width: 100%;
  height: 100px;
  background-color: white;
  padding: 20px 16px 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${(props) => props.theme.size.laptopL}) {
    width: 100%;
  }
`;

const CardTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #132005;
  padding-bottom: 5px;
  width: 100%;
`;

const CardDescription = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #28400c;
`;

export default BigSliderCard;
